import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  public set(key: string, data: string | number | {} | []): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.log('Problem on set data in storage: ', e);
    }
  }

  public setOrMerge(key: string, data: string | number | {} | []): void {
    try {
      let existingData = this.get(key);
      existingData = existingData ? { ...existingData, data } : data;

      localStorage.setItem(key, JSON.stringify(existingData));
    } catch (e) {
      console.log('Problem on set or merge data in storage: ', e);
    }
  }

  public setOrMergeArr(key: string, data: string | number | {} | []): void {
    try {
      let existingData = this.get(key);
      existingData = existingData ? [ ...existingData, data ] : [data];

      localStorage.setItem(key, JSON.stringify(existingData));
    } catch (e) {
      console.log('Problem on set or merge data in storage: ', e);
    }
  }

  public get(key: string): any {
    try {
      return JSON.parse(<string>localStorage.getItem(key));
    } catch (e) {
      console.log('Problem on get data from storage: ', e);
    }
  }

  public remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log('Problem on remove data from storage: ', e);
    }
  }

  public clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      console.log('Problem on clear storage: ', e);
    }
  }
}
