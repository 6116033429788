import { UrlService } from './url.service/url.service';
import { JwtService } from '@services/jwt.service/jwt.service';
import { RequestService } from './request.service/request.service';
import { StorageService } from './storage.service/storage.service';
import { ScreenService } from '@services/screen.service/screen.service';
import { ProductsService } from '@services/products.service/products.service';
import { ConstantsService } from '@services/constants.service/constants.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { ErrorHandlingService } from './error-handling.service/error-handling.service';
import { ConfirmModalService } from '@services/confirm-modal.service/confirm-modal.service';
import { ProductConfigFormService } from '@services/product-config-form.service/product-config-form.service';

export const SERVICES = [
  UrlService,
  JwtService,
  ScreenService,
  StorageService,
  RequestService,
  ProductsService,
  ConstantsService,
  EnvConfigService,
  ConfirmModalService,
  ErrorHandlingService,
  ProductConfigFormService,
];
