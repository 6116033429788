import { EnvConfig } from '@models/env-config';

export const ENV_CONFIG_DEFAULT: EnvConfig = {
  isLoadGenesysChat: false,

  commitHashValue: '',
  pspdfkitLicenceKey: '',
  applicationVersion: '1.0.1',
  supportMobileNumber: '8883369363',
  mobileNumberEntity: 'Synapse Health',
  mobileNumberShortcode: '773-358-1178',
  pamTeamEmail: 'pamteam@synapsehealth.com',
  supportEmail: 'support@synapsehealth.com',
  orderAssistEmail: 'orderassist@synapsehealth.com',

  refreshTokenInterval: 5,
  showInactiveWarningBefore: 5,
  autoLogoutInactiveUserAfter: 10,
  prolongLockOrderTaskInterval: 10,
};
