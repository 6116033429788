import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SVG_CONFIG } from '@const/svg-config';
import { ConstantsService } from '@services/constants.service/constants.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly constantsService: ConstantsService,
  ) {}

  public ngOnInit(): void {
    this.constantsService.getProductConstants();
    SVG_CONFIG.map(o => this.generateIcon(o.svgName, o.path));
  }

  private generateIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
