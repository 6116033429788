export const SVG_CONFIG = [
  { svgName: 'close', path: '/icons/close.svg' },
  { svgName: 'compass', path: '/icons/compass.svg' },
  { svgName: 'calculator', path: '/icons/calculator.svg' },
  { svgName: 'information', path: '/icons/information.svg' },
  { svgName: 'warning-colored', path: '/icons/warning-colored.svg' },
  { svgName: 'arrow-short-left', path: '/icons/arrow-short-left.svg' },
  { svgName: 'arrow-short-right', path: '/icons/arrow-short-right.svg' },
  { svgName: 'check_circle', path: '/icons/check_circle.svg' },
  { svgName: 'information_circle', path: '/icons/information_circle.svg' },
];
