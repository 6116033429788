import { Injectable } from '@angular/core';

import { SCREEN_WIDTH } from '@const/screen-width';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScreenService {
  public isMobileView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth <= SCREEN_WIDTH.mobile);

  constructor() {
    this.setMobileSettings();
  }

  public setMobileSettings(): void {
    this.isMobileView$.next(window.innerWidth <= SCREEN_WIDTH.mobile);
    this.isMobileView$.value ? document.body.classList.add('_mobile') : document.body.classList.remove('_mobile');
  }
}
