import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { STORAGE } from '@const/storage';
import { EnvConfig } from '@models/env-config';
import { ENV_CONFIG_URL } from '@const/base-const';
import { getUniqId } from '@helpers/uniq-id.helper';
import { ENV_CONFIG_DEFAULT } from '@const/env-config-default';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { StorageService } from '@services/storage.service/storage.service';

@Injectable()
export class EnvConfigService {
  constructor(
    private readonly httpBackend: HttpBackend,
    private readonly storageService: StorageService,
  ) {}

  public getEnvConfig$(): Observable<EnvConfig> {
    const http = new HttpClient(this.httpBackend);

    return http.get(ENV_CONFIG_URL + '?appVersion=' + (this.getCurrentEnvConfig()?.applicationVersion || getUniqId())) as Observable<EnvConfig>;
  }

  public setNewEnvConfig(envConfig: EnvConfig): void {
    this.storageService.set(STORAGE.envConfigData, envConfig);
  }

  public getCurrentEnvConfig(): EnvConfig {
    return this.storageService.get(STORAGE.envConfigData) || ENV_CONFIG_DEFAULT;
  }
}
