import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { LoadingModule } from '@directives/loading/loading.module';
import { ModalHeaderComponent } from './components/modal-header.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    LoadingModule,
    MatButtonModule,
  ],
  declarations: [
    ModalHeaderComponent,
  ],
  exports: [
    ModalHeaderComponent,
  ],
})
export class ModalHeaderModule {}
